/* eslint-disable complexity */
import React, { useEffect } from 'react'
import { BookImage } from '@shared/interfaces/BookImage'
import StickySubHeader from '@site/components/molecules/StickySubHeader'
import * as S from './styles'
import { Category } from '@shared/interfaces/Category'
import { PreviousKitAnimationContext } from '@site/contexts/PreviousKitAnimationContext'
import AnimatedBookHeader from './AnimatedBookHeader'
import StaticBookHeader from './StaticBookHeader'

const BookHeader = ( { bookTitle, bookImage, next, previous, category }: BookHeader.Props ): React.ReactElement => {
  const { shouldAnimate, setShouldAnimate } = React.useContext( PreviousKitAnimationContext )

  useEffect( () => {
    if ( shouldAnimate ) {
      setTimeout( () => {
        setShouldAnimate( false )
      }, 600 )
    }
  }, [] )

  return (
    <>
      <StickySubHeader
        backgroundColor={ bookImage.starHexColor }
        ribbonColor={ bookImage.flagHexColor }
        title={ bookTitle.length <= 29 ? bookTitle : `${bookTitle.substring( 0, 30 )}...` }
        displayRibbonBeforeScroll={ true }
        animate={ shouldAnimate }
      />

      <S.HeaderBackground>
        { shouldAnimate ?
          <AnimatedBookHeader
            bookTitle={ bookTitle }
            bookImage={ bookImage }
            category={ category }
            next={ next }
            previous={ previous }
          />
          :
          <StaticBookHeader
            bookTitle={ bookTitle }
            bookImage={ bookImage }
            category={ category }
            next={ next }
            previous={ previous }
          /> }
      </S.HeaderBackground>
    </>
  )
}

namespace BookHeader {
  export interface Props {
    bookTitle: string
    bookImage: BookImage
    next: string
    previous: string
    category: Category.Type
  }
}

export default BookHeader
