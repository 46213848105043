import styled from 'styled-components'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  .book-rating-modal__star {
    margin: auto;
    margin-bottom: 4px;
  }
  > p {
    margin-top: 8px;
  }
  .book-rating-modal__button {
    margin-top: 24px;
    padding: 12px 0;
  }
`

export { Content }
