/* eslint-disable react/display-name */
/* eslint-disable complexity */
import React from 'react'
import { BookImage } from '@shared/interfaces/BookImage'
import TagRibbon from '@site/components/atoms/TagRibbon'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { ReactComponent as RoundTopHelperSVG } from '@site/assets/helpers/rounded-top.svg'
import { ReactComponent as RoundLeftHelperSVG } from '@site/assets/helpers/rounded-left.svg'
import * as S from './styles'
import { Category } from '@shared/interfaces/Category'
import { motion } from 'framer-motion'
import { PreviousKitAnimationContext } from '@site/contexts/PreviousKitAnimationContext'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { Link } from 'gatsby'

const BackgroundImageRefWrapper = React.forwardRef<any, any>( ( props, ref ) => {
  const { children, ...rest } = props

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - famoso erro TS2589
    <S.HeaderBackgroundImage
      { ...rest }
      ref={ ( instance: any ) => ( ref as any )( ( instance as any )?.selfRef ) }
    >
      {children}
    </S.HeaderBackgroundImage>
  )
} )

const CoverImageRefWrapper = React.forwardRef<any, any>( ( props, ref ) => {
  const { image, alt, className, ...rest } = props

  return (
    <div
      ref={ ref }
      className={ className }
      { ...rest }
    >
      <GatsbyImage
        className={ className }
        image={ image }
        alt={ alt }
      />
    </div>
  )
} )

const MotionBackgroundImage = motion( BackgroundImageRefWrapper )
const MotionCoverImage = motion( CoverImageRefWrapper )
const MotionArrowLeft = motion( S.ArrowAnchor )
const MotionArrowRight = motion( S.ArrowAnchor )

const AnimatedBookHeader = ( { bookTitle, bookImage, next, previous, category }: AnimatedBookHeader.Props ): React.ReactElement => {
  const color = category === 'curadoria' ? '#1B2A68' : '#B74D00'
  const { selectedBookCoverPosition, setSelectedBookCoverPosition } = React.useContext( PreviousKitAnimationContext )
  const coverImage = getImage( bookImage.cover.localFile )!
  const backgroundImage = convertToBgImage( getImage( bookImage.background.localFile ) )

  return (
    <>
      <MotionBackgroundImage
        { ...backgroundImage }
        initial={ selectedBookCoverPosition ? { 'y': '-100%' } : {} }
        animate={ selectedBookCoverPosition ? { 'y': '0' } : {} }
        transition={ selectedBookCoverPosition ? { 'duration': 0.5 } : {} }
        className=" book-sleeve mobile-only"
      >
        <RoundTopHelperSVG
          data-testid="animated-book-header"
          className="book-header__round-top"
          height="14"
          width="100%"
        />
      </MotionBackgroundImage>

      <MotionBackgroundImage
        { ...backgroundImage }
        initial={ selectedBookCoverPosition ? { 'x': '-100%' } : {} }
        animate={ selectedBookCoverPosition ? { 'x': '0' } : {} }
        transition={ selectedBookCoverPosition ? { 'duration': 0.5 } : {} }
        className="book-sleeve desktop-only"
      >
        <RoundLeftHelperSVG
          className="book-header__round-left"
          height="100%"
          width="14"
        />
      </MotionBackgroundImage>

      <S.DesktopHelper>
        <S.ContentContainer>
          <motion.div
            initial={ selectedBookCoverPosition ? { 'y': '-100%' } : {} }
            animate={ selectedBookCoverPosition ? { 'y': '0' } : {} }
            transition={ selectedBookCoverPosition ? { 'duration': 0.5 } : {} }
            className="desktop-only"
          >
            <TagRibbon
              color={ bookImage.flagHexColor }
              height={ 90 }
              width={ 64 }
            />
          </motion.div>

          <MotionArrowLeft
            initial={ selectedBookCoverPosition ? { 'opacity': '0', 'x': '-20px' } : {} }
            animate={ selectedBookCoverPosition ? { 'opacity': [ '0', '1' ], 'x': '0' } : {} }
            transition={ selectedBookCoverPosition ? { 'duration': 0.5 } : {} }
            $color={ color }
            $disabled={ !previous }
            className="book-header__left-arrow-anchor mobile-only"
          >
            <Link to={ previous ? `${process.env[ 'GATSBY_SITE_PATH' ]}${previous}` : '#' }>
              <ArrowLeft color={ color } />
            </Link>
          </MotionArrowLeft>

          <MotionArrowLeft
            initial={ selectedBookCoverPosition ? { 'opacity': '0', 'y': '20px' } : {} }
            animate={ selectedBookCoverPosition ? { 'opacity': [ '0', '1' ], 'y': '0' } : {} }
            transition={ selectedBookCoverPosition ? { 'duration': 0.5 } : {} }
            $color={ color }
            $disabled={ !previous }
            className="book-header__left-arrow-anchor desktop-only"
          >
            <Link
              to={ previous ? `${process.env[ 'GATSBY_SITE_PATH' ]}${previous}` : '#' }
              onClick={ () => setSelectedBookCoverPosition( null ) }
            >
              <ArrowLeft color={ color } />
            </Link>
          </MotionArrowLeft>

          <S.BookCover>
            <MotionCoverImage
              initial={ selectedBookCoverPosition ? {
                'y': `${selectedBookCoverPosition.top}px`,
                'x': `${selectedBookCoverPosition.left}px`,
                'width': `${selectedBookCoverPosition.width}px`,
                'height': 'auto'
              } : {} }
              animate={ selectedBookCoverPosition ? { 'y': '0', 'x': '0', 'width': 'auto', 'height': '310px' } : {} }
              transition={ selectedBookCoverPosition ? { 'duration': 0.5 } : {} }
              image={ coverImage }
              alt={ `Capa do livro ${bookTitle}` }
              className="mobile-only"
            />

            <MotionCoverImage
              initial={ selectedBookCoverPosition ? { 'opacity': '0' } : {} }
              animate={ selectedBookCoverPosition ? { 'opacity': [ '0', '1' ] } : {} }
              transition={ selectedBookCoverPosition ? { 'duration': 0.5 } : {} }
              image={ coverImage }
              alt={ `Capa do livro ${bookTitle}` }
              className="desktop-only"
            />
          </S.BookCover>

          <MotionArrowRight
            initial={ selectedBookCoverPosition ? { 'opacity': '0', 'x': '20px' } : {} }
            animate={ selectedBookCoverPosition ? { 'opacity': [ '0', '1' ], 'x': '0' } : {} }
            transition={ selectedBookCoverPosition ? { 'duration': 0.5 } : {} }
            $color={ color }
            $disabled={ !next }
            className="book-header__right-arrow-anchor mobile-only"
          >
            <Link
              onClick={ () => setSelectedBookCoverPosition( null ) }
              to={ next ? `${process.env[ 'GATSBY_SITE_PATH' ]}${next}` : '#' }
            >
              <ArrowRight color={ color } />
            </Link>
          </MotionArrowRight>

          <MotionArrowRight
            initial={ selectedBookCoverPosition ? { 'opacity': '0', 'y': '20px' } : {} }
            animate={ selectedBookCoverPosition ? { 'opacity': [ '0', '1' ], 'y': '0' } : {} }
            transition={ selectedBookCoverPosition ? { 'duration': 0.5 } : {} }
            $color={ color }
            $disabled={ !next }
            className="book-header__right-arrow-anchor desktop-only"
          >
            <Link to={ next ? `${process.env[ 'GATSBY_SITE_PATH' ]}${next}` : '#' }>
              <ArrowRight color={ color } />
            </Link>
          </MotionArrowRight>
        </S.ContentContainer>
      </S.DesktopHelper>
    </>
  )
}

namespace AnimatedBookHeader {
  export interface Props {
    bookTitle: string
    bookImage: BookImage
    next: string
    previous: string
    category: Category.Type
  }
}

export default AnimatedBookHeader
