import React from 'react'
import { ReactComponent as EmptyStar } from '@site/assets/helpers/star-empty-fill.svg'
import { ReactComponent as HalfStar } from '@site/assets/helpers/star-half-fill.svg'
import { ReactComponent as FullStar } from '@site/assets/helpers/star-full-fill.svg'


const starsFillHelpper = ( { rating } : StarsFillHelpper.Props ): JSX.Element => {
  const ratingToRound = rating * 2

  const renderStar = ( value:number ) => {
    if ( value <= 0 ) {
      return <EmptyStar className="empty-star" />
    } else if ( value >= 1 && value < 1.6 ) {
      return <HalfStar className="half-star" />
    } else {
      return <FullStar className="full-star" />
    }
  }

  return (
    <>
      {renderStar( ratingToRound )}
      {renderStar( Math.round( ratingToRound - 2 ) )}
      {renderStar( Math.round( ratingToRound - 4 ) )}
      {renderStar( Math.round( ratingToRound - 6 ) )}
      {renderStar( Math.round( ratingToRound - 8 ) )}
    </> )
}


namespace StarsFillHelpper {
  export interface Props {
    rating: number
  }

}

export default starsFillHelpper
