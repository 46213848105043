import styled from 'styled-components'

const Container = styled.div`
  padding-top: 16px;
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    padding: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const NavigatorShare = styled.button`
  height: 40px;
  width: 40px;
  margin-top: 4px;
  border-radius: 50%;
  background-color: #F9F9F9;
  > svg {
    margin: 8px;
  }
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    display: none;
  }
`

const Details = styled.div`
  margin-top: 4px;
  > p {
    margin-bottom: 2px;
  }
`
const Title = styled.div`
  margin-bottom: 2px;
  > h2 {
    line-height: 26px;
    letter-spacing: -0.025em;
  }
`


export { Container, Wrapper, Details, NavigatorShare, Title }
