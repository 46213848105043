interface WithShare extends Navigator {
  canShare( data: ShareData ): boolean
}

export const hasNativeShare = ( navigator: Navigator ): navigator is WithShare => {
  return 'canShare' in navigator
}

export const share = async( data: ShareData ): Promise<boolean> => {
  if ( typeof window !== 'undefined' && hasNativeShare( navigator ) && navigator.canShare( data ) ) {
    await navigator.share( data )
    return true
  } else {
    return false
  }
}
