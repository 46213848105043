import React from 'react'
import * as S from './styles'
import { Typography } from '@taglivros/tag-components'
import { ReactComponent as Star } from '../../../images/kitsPassados/star.svg'
import Modal from '@taglivros/oef_componentes/build/components/modal'
import Button from '@site/components/atoms/Button'
import { previousKitsTexts } from '@shared/interfaces/BookTexts'
import { Category } from '@shared/interfaces/Category'
import { colors } from '@shared/interfaces/Colors'

const BookRatingModal = ( { show, onClose, category } : BookRatingModal.Props ): React.ReactElement => {
  return (
    <Modal
      altura="auto"
      largura="450px"
      show={ show }
    >
      <S.Content className="book-rating-modal__content">
        <Star className="book-rating-modal__star" />
        <Typography
          as="p"
          fontFamily="notoSans"
          fontWeight="xl"
          variant="text2"
        >
          Avaliação dos Taggers
        </Typography>
        <Typography
          as="p"
          fontFamily="notoSans"
          fontWeight="s"
          variant="text3"
        >
          { previousKitsTexts.RATING_TEXT }
        </Typography>
        <Button
          backgroundColor={ colors[ category ].SOLID!.BUTTON_BACKGROUND }
          color={ colors[ category ].SOLID!.BUTTON_COLOR }
          className="book-rating-modal__button"
          hover={ colors[ category ].SOLID!.BUTTON_HOVER }
          onClick={ onClose }
        >
          { previousKitsTexts.CLOSE_BUTTON }
        </Button>
      </S.Content>
    </Modal>
  )
}

namespace BookRatingModal {
  export interface Props {
    show: boolean
    onClose( event: React.MouseEvent<HTMLAnchorElement> ): void
    category: Category.Type
  }
}

export default BookRatingModal
