import styled from 'styled-components'

const Container = styled.div`
  cursor: pointer;
  height: auto;
  margin: 16px 0;
  padding: 16px;
  line-height: 20px;
  box-shadow: 0px 1px 4px rgba(19, 26, 45, 0.2);
  border-radius: 8px;

  .commentary-box--curadoria__content,
  .commentary-box--ineditos__content {
    height: inherit;
    margin-bottom: 8px;
    line-height: 24px;
    letter-spacing: -0.01em;
    font-weight: inherit;
    font-size: inherit;

    &, p, strong, em {
      font-family: inherit;
    }
  }  
`

export { Container }
