import { Category } from '@shared/interfaces/Category'
import { Typography } from '@taglivros/tag-components'
import React, { useState } from 'react'
import * as S from './styles'
import CommentaryBox from '@site/components/molecules/CommentaryBox'
import BookRatingModal from '@site/components/molecules/BookRatingModal'
import { BookRating } from '@shared/interfaces/BookRating'
import { colors } from '@shared/interfaces/Colors'
import { previousKitsTexts } from '@shared/interfaces/BookTexts'

const BookRatings = ( { category, title, comments } : BookRatings.Props ): React.ReactElement => {
  const [ showModal, setShowModal ] = useState<boolean>( false )

  const handleOnClose = ( e:React.MouseEvent<HTMLAnchorElement> ) => {
    e.stopPropagation()
    setShowModal( false )
  }

  return (
    <S.Container className={ `book-ratings--${category}` }>

      <Typography
        color={ colors[ category ].TITLE! }
        as="h2"
        fontFamily="notoSans"
        fontWeight="xl"
        fontSizeMobile="m"
        fontSizeDesktop="m"
        variant="customText"
      >
        { title }
      </Typography>

      {comments.map( ( comment: BookRating ) => {
        return (
          <CommentaryBox
            category={ category }
            key={ comment.id }
            commentary={ comment }
          /> )
      } )}

      <S.EventWrapper onClick={ () => setShowModal( true ) } >
        <Typography
          color="GREY-500-TXT"
          as="p"
          fontFamily="notoSans"
          fontWeight="s"
          fontSizeMobile="xxs"
          fontSizeDesktop="xxs"
          variant="customText"
        >
          { previousKitsTexts.BOOKRATINGS_FOOTER }
        </Typography>
        <BookRatingModal
          onClose={ handleOnClose }
          show={ showModal }
          category={ category }
        />
      </S.EventWrapper>
    </S.Container>
  )
}

namespace BookRatings {
  export interface Props {
    category: Category.Type
    title: string
    comments: BookRating[]
  }
}

export default BookRatings
