import styled from 'styled-components'

const Container = styled.section`
  > p {
    text-align: right;

    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      text-align: left;
    }
  }

  + div {
    margin: 16px 0;

    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      margin: 24px 0;
    }
  }
`

const PhotosContainer = styled.div`
  display: flex;
  overflow: scroll;
  padding: 16px 3px 6px 0;

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    flex-wrap: wrap;
    overflow: auto;
    padding: 24px 3px 2px;
    margin-left: -3px;
  }
`

const Photo = styled.div`
  filter: drop-shadow(0px 1px 4px rgba(19, 26, 45, 0.2));
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 8px;
  }
  
  > div > div {
    width: 100px;
    height: 100px;
  }

  img {
    border-radius: 4px;
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    margin-bottom: 8px;
  }
`

export { Container, PhotosContainer, Photo }
