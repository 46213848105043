import { tagTheme } from '@taglivros/tag-components'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    justify-content: initial;
  }
`

const CategoryWrapper = styled.div<CategoryWrapper.Props>`
  display: flex;
  width: 100%;
  letter-spacing: 0.025em;
  p, a {
    width: auto;
    display: flex;
  }
  p {
    margin-left: 4px;
  }
  svg {
    path {
      fill: ${props => props.theme.cores[ props.color ]};
    }
  }
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    width: auto;
  }
`
namespace CategoryWrapper {
  export interface Props {
    color: keyof typeof tagTheme.cores
  }
}

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  > svg {
    margin: 5px 0 0 6px;
  }
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    margin: 0 16px;
  }
`

export { Container, CategoryWrapper, RatingWrapper }
