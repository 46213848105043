/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-unstable-nested-components */
import { Typography } from '@taglivros/tag-components'
import React, { useState } from 'react'
import * as S from './styles'
import Stars from '../../atoms/Stars'
import ReactMarkdown from 'react-showdown'
import BookRatingModal from '@site/components/molecules/BookRatingModal'
import { Category } from '@shared/interfaces/Category'
import { BookRating } from '@shared/interfaces/BookRating'

const CommentaryBox = ( { category, commentary } : CommentaryBox.Props ): React.ReactElement => {
  const [ showModal, setShowModal ] = useState<boolean>( false )

  const handleOnClose = ( e:React.MouseEvent<HTMLAnchorElement> ) => {
    e.stopPropagation()
    setShowModal( false )
  }

  return (
    <S.Container
      className={ `commentary-box--${category}` }
      onClick={ () => setShowModal( true ) }
    >
      <Stars
        rating={ commentary.rating }
      />
      <Typography
        color="GREY-700-TXT"
        as="p"
        fontFamily="notoSans"
        fontWeight="s"
        fontSizeMobile="s"
        fontSizeDesktop="s"
        variant="customText"
      >
        <ReactMarkdown
          className={ `commentary-box--${category}__content` }
          markdown={ commentary.comment }
        />
      </Typography>

      <Typography
        color="GREY-500-TXT"
        as="p"
        fontFamily="notoSans"
        fontWeight="s"
        fontSizeMobile="xs"
        fontSizeDesktop="xs"
        variant="customText"
      >
        {commentary.name}
      </Typography>
      <BookRatingModal
        onClose={ handleOnClose }
        show={ showModal }
        category={ category }
      />
    </S.Container>

  )
}

namespace CommentaryBox {
  export interface Props {
    category: Category.Type
    commentary: BookRating
}
}

export default CommentaryBox
