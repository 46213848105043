import { Category } from '@shared/interfaces/Category'
import { BookSynopsis } from '@shared/interfaces/BookSynopsis'
import React from 'react'
import TextBlock from '../../molecules/TextBlock'

const BookComplementary = ( { category, aboutBook } : BookComplementary.Props ): React.ReactElement => {
  let authorPronoun: string | undefined
  if ( aboutBook.authorshipPronoun === 'autor' ) {
    authorPronoun = ' o'
  } else if ( aboutBook.authorshipPronoun === 'autora' ) {
    authorPronoun = ' a'
  }

  return (
    <div className="book-complementary__wrapper">
      {aboutBook.synopsis &&
      <TextBlock
        category={ category }
        title="Sinopse"
      >
        { aboutBook.synopsis }
      </TextBlock>}
      {aboutBook.aboutAuthorship &&
      <TextBlock
        category={ category }
        title={ authorPronoun ? `Sobre${authorPronoun} ${aboutBook.authorshipPronoun}` : `Sobre ${aboutBook.authorshipPronoun}` }
      >
        { aboutBook.aboutAuthorship }
      </TextBlock>}

      {aboutBook.customTitleParagraph && aboutBook.customTextParagraph &&
      <TextBlock
        category={ category }
        title={ aboutBook.customTitleParagraph }
      >
        {aboutBook.customTextParagraph}
      </TextBlock>}
    </div>
  )
}

namespace BookComplementary {
  export interface Props {
    category: Category.Type
    aboutBook: BookSynopsis
  }

}
export default BookComplementary
