/* eslint-disable id-length */
import React, { useState } from 'react'
import { BookGalleryPhoto } from '@shared/interfaces/BookGalleryPhoto'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Typography } from '@taglivros/tag-components'
import { previousKitsTexts } from '@shared/interfaces/BookTexts'
import GalleryModal from '../../../../../shared/components/molecules/GalleryModal'
import * as S from './styles'

const BookGallery = ( { galleryPhotos } : BookGallery.Props ): React.ReactElement => {
  const [ displayModal, setDisplayModal ] = useState( false )
  const [ modalPhotoPosition, setModalPhotoPosition ] = useState( 0 )

  const handlePhotoClick = ( photoIndex: number ) => {
    setModalPhotoPosition( photoIndex )
    setDisplayModal( true )
  }

  return (
    <S.Container className="gallery">
      <S.PhotosContainer>
        { galleryPhotos.map( ( photo, index ) => {
          const image = getImage( photo.image.localFile )

          return image && (
            <S.Photo
              key={ photo.id }
              className="gallery__photo"
              onClick={ () => {
                handlePhotoClick( index )
              } }
            >
              <GatsbyImage
                image={ image }
                alt={ photo.caption }
              />
            </S.Photo>
          )
        } ) }
      </S.PhotosContainer>

      <Typography
        as="p"
        fontSizeDesktop="xxs"
        fontSizeMobile="xxs"
        fontWeight="s"
        fontFamily="notoSans"
        color="GREY-500-TXT"
        variant="customText"
      >
        { previousKitsTexts.GALLERY_CLICK_ON_PHOTO }
      </Typography>

      <GalleryModal
        galleryPhotos={ galleryPhotos }
        currentPhotoPosition={ modalPhotoPosition }
        setCurrentPhotoPosition={ setModalPhotoPosition }
        displayModal={ displayModal }
        setDisplayModal={ setDisplayModal }
      />
    </S.Container>
  )
}

namespace BookGallery {
  export interface Props {
    galleryPhotos: BookGalleryPhoto[]
  }
}

export default BookGallery
