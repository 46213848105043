import React, { useState } from 'react'
import * as S from './styles'
import * as PathNavigation from '../../PathNavigation'
import BookRatingModal from '@site/components/molecules/BookRatingModal'
import { ReactComponent as Flag } from '../../../images/kitsPassados/flag.svg'
import { ReactComponent as Star } from '../../../images/kitsPassados/star-rating.svg'
import { ReactComponent as HelpCircle } from '../../../images/kitsPassados/help-circle.svg'
import { Pill, Typography } from '@taglivros/tag-components'
import { Category } from '@shared/interfaces/Category'
import { colors } from '@shared/interfaces/Colors'
import { Link } from 'gatsby'
import { bookTextsCategory } from '@shared/interfaces/BookTexts'

const BookMetadataHeader = ( { tagMonth, appRating, category }: BookMetadataHeader.Props ): React.ReactElement => {
  const [ showModal, setShowModal ] = useState<boolean>( false )

  const handleOnClose = ( e:React.MouseEvent<HTMLAnchorElement> ) => {
    e.stopPropagation()
    setShowModal( false )
  }

  return (
    <S.Container className="book-metadata-header">
      <S.CategoryWrapper
        color={ colors[ category ].DEFAULT! }
        className="book-metadata-header__category-wrapper"
      >
        <Link
          className="book-metadata-header__category-button"
          to={ PathNavigation[ category ] }
        >
          <Flag className="book-metadata-header__flag" />
          <Typography
            as="p"
            color={ category === 'curadoria' ? 'TAG1-700' : 'TAG2-650-TXT' }
            fontFamily="notoSans"
            fontWeight="l"
            variant="helperText"
          >
            TAG { bookTextsCategory[ category ].CATEGORY }
          </Typography>
        </Link>
        <Typography
          as="p"
          color="GREY-500-TXT"
          fontFamily="notoSans"
          fontWeight="s"
          variant="helperText"
        >
          { tagMonth }
        </Typography>
      </S.CategoryWrapper>
      <S.RatingWrapper
        onClick={ () => setShowModal( true ) }
        className="book-metadata-header__rating-wrapper"
      >
        <Pill
          Icon={ Star }
          content={
            <Typography
              as="p"
              color="GREY-700-TXT"
              fontWeight="s"
              fontFamily="notoSans"
              variant="text3"
            >
              { appRating?.toString() || '—' }
            </Typography>
          }
        />
        <HelpCircle />
      </S.RatingWrapper>
      <BookRatingModal
        onClose={ handleOnClose }
        show={ showModal }
        category={ category }
      />
    </S.Container>
  )
}

namespace BookMetadataHeader {
  export interface Props {
    tagMonth: string
    appRating: number | undefined
    category: Category.Type
  }
}

export default BookMetadataHeader
