import styled from 'styled-components'

const Container = styled.div<Container.Props>`
  display: ${props => props.$displayModal ? 'block' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

namespace Container {
  export interface Props {
    $displayModal: boolean
  }
}

const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 100001;
  margin: 8px;

  > *:not(:first-child) {
    margin-top: 8px;
  }

  img {
    border-radius: 8px;
    filter: drop-shadow(0px 1px 4px rgba(19, 26, 45, 0.2));

    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      max-width: 1500px;
      max-height: 60vh;
    }
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Caption = styled.div`
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${props => props.theme.cores.WHITE};
  filter: drop-shadow(0px 1px 4px rgba(19, 26, 45, 0.2));
  text-align: center;
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100000;

  background: #000C40;
  opacity: 0.5;
`

export { Container, Content, Caption, Backdrop }
