import { tagTheme } from '@taglivros/tag-components'
import styled from 'styled-components'

const Container = styled.main`
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    position: relative;
    display: flex;
    margin-top: 64px;
  }
`

const ContainerContent = styled.div<PreviousKits.Props>`
  padding: 0 24px;

  > a {
    width: auto;
    display: flex;
    margin: 16px 0px;
    svg {
      path {
        stroke: ${props => props.theme.cores[ props.color ]};
      }
    }
  }


  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    width: 550px;
    padding: 40px 40px 40px 120px;
    box-sizing: content-box;

    > a {
      display: none;
    }
  }
`
const ButtonWrapper = styled.div<PreviousKits.Props>`
  display: none;
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    display: flex;
    width: 114px;
    margin-bottom: 30px;
    > a {
      padding: 0 16px 0 0;
      svg {
        path {
          stroke: ${props => props.theme.cores[ props.color ]};
        }
      }
    }
  }
`

namespace PreviousKits {
  export interface Props {
    color: keyof typeof tagTheme.cores
  }
}

export { Container, ContainerContent, ButtonWrapper }
