import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { BookGalleryPhoto } from '@shared/interfaces/BookGalleryPhoto'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Typography } from '@taglivros/tag-components'
import GalleryModalButtons from '@shared/components/molecules/GalleryModalButtons'
import Markdown from 'react-showdown'
import * as S from './styles'

// tipar como ReactNode dá erro nos componentes acima. verificar e corrigir assim que possível.
const GalleryModal = ( props : GalleryModal.Props ): any => {
  const {
    galleryPhotos,
    currentPhotoPosition,
    setCurrentPhotoPosition,
    displayModal = false,
    setDisplayModal,
    showSlideControls = true,
  } = props

  const localFile = galleryPhotos[ currentPhotoPosition ]?.image.localFile

  useEffect( () => {
    if ( localFile ) {
      setImage( getImage( localFile ) )
    }
  }, [ localFile ] )

  if ( !localFile ) {
    return null
  }

  const [ image, setImage ] = useState( getImage( localFile ) )

  return (
    <S.Container
      $displayModal={ displayModal }
      className="gallery-modal"
    >
      <S.Content className="gallery-modal__photo">
        { image && (
          <GatsbyImage
            image={ image }
            alt={ galleryPhotos[ currentPhotoPosition ]?.caption || '' }
          />
        ) }

        <S.Caption
          className="gallery-modal__caption"
        >
          <Typography
            as="p"
            fontSizeDesktop="xxs"
            fontSizeMobile="xxs"
            fontWeight="s"
            fontFamily="notoSans"
            color="GREY-700-TXT"
            variant="customText"
          >
            <Markdown markdown={ galleryPhotos[ currentPhotoPosition ]?.caption || '' } />
          </Typography>
        </S.Caption>
        <GalleryModalButtons
          currentPhotoPosition={ currentPhotoPosition }
          galleryPhotos={ galleryPhotos }
          setCurrentPhotoPosition={ setCurrentPhotoPosition }
          setDisplayModal={ setDisplayModal }
          showSlideControls={ showSlideControls }
        />
      </S.Content>

      <S.Backdrop
        className="gallery-modal__backdrop"
        onClick={ () => {
          setDisplayModal( false )
        } }
      />
    </S.Container>
  )
}

namespace GalleryModal {
  export interface Props {
    galleryPhotos: BookGalleryPhoto[]
    currentPhotoPosition: number
    setCurrentPhotoPosition: Dispatch<SetStateAction<number>>
    displayModal: boolean
    setDisplayModal: Dispatch<SetStateAction<boolean>>
    showSlideControls?: boolean
  }
}

export default GalleryModal
