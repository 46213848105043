import React, { useEffect, useState } from 'react'
import { ArrowLeft } from 'react-feather'
import { Typography, tagTheme } from '@taglivros/tag-components'
import { getContrastColor } from '@site/scripts/getContrastColor'
import TagRibbon from '@site/components/atoms/TagRibbon'
import * as PathNavigation from '../../PathNavigation'
import * as S from './styles'
import { motion } from 'framer-motion'

const StickySubHeader = ( { backgroundColor, categoryData, ribbonColor, title, animate, displayRibbonBeforeScroll = false }: StickySubHeader.Props ): React.ReactElement => {
  const [ scrollPosition, setScrollPosition ] = useState( 0 )
  const MotionContainer = motion( S.Container )

  const handleReturnTopClick = () => {
    window.scrollTo( { 'top': 0, 'behavior': 'smooth' } )
  }

  useEffect( () => {
    setScrollPosition( window.scrollY )

    window.onscroll = () => {
      setScrollPosition( window.scrollY )
    }
  }, [] )

  function colorHandler( titleBackgroundColor:string, content?:string ) : keyof typeof tagTheme.cores {
    if ( content && ( content === categoryData?.curadoria?.title || content === categoryData?.ineditos?.title ) ) {
      return 'WHITE'
    } else {
      return getContrastColor( titleBackgroundColor )
    }
  }

  return (
    <MotionContainer
      initial={ animate && { 'y': '-150%' } }
      animate={ animate && { 'y': [ '-150%', '0%' ] } }
      transition={ animate && { 'duration': 0.5 } }
      $backgroundColor={ backgroundColor }
      $scrollPosition={ scrollPosition }
      $displayRibbonBeforeScroll={ displayRibbonBeforeScroll }
      className="mobile-only"
    >
      <S.ArrowLink
        $scrollPosition={ scrollPosition }
        to={ PathNavigation.kitsPassados }
      >
        <ArrowLeft
          className="return-button"
          color={ colorHandler( backgroundColor, title ) === 'WHITE' ? '#fff' : '#110E22' }
        />
      </S.ArrowLink>
      <S.Title
        onClick={ handleReturnTopClick }
        $scrollPosition={ scrollPosition }
      >
        <Typography
          as="h1"
          color={ colorHandler( backgroundColor, title ) }
          fontSizeDesktop="xs"
          fontSizeMobile="xs"
          fontWeight="l"
          fontFamily="notoSans"
          variant="customText"
        >
          { title }
        </Typography>
      </S.Title>
      <TagRibbon
        color={ ribbonColor }
        height={ 87 }
        width={ 64 }
        withShadow={ true }
        onClick={ handleReturnTopClick }
      />
    </MotionContainer>
  )
}

namespace StickySubHeader {
  export interface Props {
    backgroundColor: string
    categoryData?: {
      'curadoria': {
        'color': string
        'logo': JSX.Element
        'title': string
      },
      'ineditos': {
        'color': string
        'logo': JSX.Element
        'title': string
      },
    }
    ribbonColor: string
    displayRibbonBeforeScroll?: boolean
    title: string
    animate: boolean
  }
}

export default StickySubHeader
