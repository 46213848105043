import styled from 'styled-components'

const Container = styled.div`
  max-width: 550px;
  line-height: 20px;
  h2 {
    letter-spacing: -0.01em;
    margin-bottom: 8px;
  }
  p:first-of-type {
    margin-bottom: 24px;
  }
  > p > div {
  font-weight: inherit;
  font-size: inherit;

    &, p, strong, em {
      font-family: inherit;
    }
  }
`

export { Container }
