import React from 'react'
import * as S from './styles'
import { Category } from '@shared/interfaces/Category'
import { Book } from '@shared/interfaces/Book'
import BookMetadataHeader from '@site/components/molecules/BookMetadataHeader'
import { Typography } from '@taglivros/tag-components'
import { ReactComponent as Share } from '../../../images/kitsPassados/share.svg'
import { useLocation } from '@reach/router'
import { share, hasNativeShare } from '@shared/utils/navigatorShare'
import { bookTextsCategory } from '@shared/interfaces/BookTexts'
import { formatMonthAndYearKit } from '@shared/utils/formatter'
import { dispatchGlobalEvent } from '@shared/services/tracking'

const BookMetadata = ( { book, category }: BookMetadata.Props ): React.ReactElement => {
  const location = useLocation()
  const tagMonth = formatMonthAndYearKit( book?.book?.tagMonth )
  const bookInfo = `"${book?.book?.title}", ${book?.book?.authorship}`
  const shareData = {
    'title': book?.book?.title,
    'url': location.href,
    'text': `${bookInfo} | Tag ${bookTextsCategory[ category ].CATEGORY} (${tagMonth})`
  }
  return (
    <S.Container className="book-metadata">
      <BookMetadataHeader
        appRating={ book?.book?.appRating }
        tagMonth={ tagMonth }
        category={ category }
      />
      <S.Wrapper className="book-metadata__wrapper">
        <S.Details className="book-metadata__details">
          <S.Title
            className="book-metada__title"
            onClick={ () => window.scrollTo( { 'top': 0, 'behavior': 'smooth' } ) }
          >
            <Typography
              as="h2"
              fontFamily="notoSans"
              fontSizeDesktop="xl"
              fontSizeMobile="xl"
              fontWeight="xl"
              variant="customText"
            >
              { book?.book?.title }
            </Typography>
          </S.Title>
          <Typography
            as="p"
            color="GREY-700-TXT"
            fontFamily="notoSans"
            fontWeight="s"
            variant="text3"
          >
            { book?.book?.authorship }
          </Typography>
          {book?.book?.additionalInformation &&
          <Typography
            as="p"
            color="GREY-500-TXT"
            fontFamily="notoSans"
            fontWeight="s"
            variant="text3"
          >
            { book?.book?.additionalInformation }
          </Typography>}

        </S.Details>
        { typeof navigator !== 'undefined' && hasNativeShare( navigator ) &&
          <S.NavigatorShare
            className="book-metadata__navigator-share"
            onClick={ () => {
              dispatchGlobalEvent( 'Kits Passados', 'share' )
              share( shareData )
            } }
          >
            <Share />
          </S.NavigatorShare> }
      </S.Wrapper>
    </S.Container>
  )
}

namespace BookMetadata {
  export interface Props {
    book: Book
    category: Category.Type
  }
}

export default BookMetadata
