import React, { Dispatch, SetStateAction } from 'react'
import { BookGalleryPhoto } from '@shared/interfaces/BookGalleryPhoto'
import { ReactComponent as CloseIcon } from '@shared/images/close-circle.svg'
import { ReactComponent as ArrowLeft } from '@shared/images/simple-arrow-left.svg'
import { ReactComponent as ArrowRight } from '@shared/images/simple-arrow-right.svg'
import * as S from './styles'

const GalleryModalButtons = ( props : GalleryModalButtons.Props ): React.ReactElement => {
  const {
    galleryPhotos,
    currentPhotoPosition,
    setCurrentPhotoPosition,
    setDisplayModal,
    showSlideControls = true
  } = props

  const handlePreviousButtonClick = ( event: React.MouseEvent<HTMLButtonElement> ) => {
    event.stopPropagation()

    if ( currentPhotoPosition > 0 ) {
      setCurrentPhotoPosition( currentPhotoPosition - 1 )
    }
  }

  const handleNextButtonClick = ( event: React.MouseEvent<HTMLButtonElement> ) => {
    event.stopPropagation()

    if ( currentPhotoPosition < galleryPhotos.length - 1 ) {
      setCurrentPhotoPosition( currentPhotoPosition + 1 )
    }
  }

  return (
    <S.Container
      onClick={ () => setDisplayModal( false ) }
      className="modal-buttons"
    >
      {showSlideControls ? (
        <>
          <S.Button
            onClick={ handlePreviousButtonClick }
            $disabled={ currentPhotoPosition === 0 }
            className="modal-buttons__previous"
          >
            <ArrowLeft />
          </S.Button>

          <S.Button
            onClick={ () => setDisplayModal( false ) }
            className="modal-buttons__close"
          >
            <CloseIcon />
          </S.Button>

          <S.Button
            onClick={ handleNextButtonClick }
            $disabled={ currentPhotoPosition === galleryPhotos.length - 1 }
            className="modal-buttons__next"
          >
            <ArrowRight />
          </S.Button>
        </>
      ) : (
        <S.Button
          onClick={ () => setDisplayModal( false ) }
          className="modal-buttons__close"
        >
          <CloseIcon />
        </S.Button>
      )}
    </S.Container>
  )
}

namespace GalleryModalButtons {
  export interface Props {
    galleryPhotos: BookGalleryPhoto[]
    currentPhotoPosition: number
    setCurrentPhotoPosition: Dispatch<SetStateAction<number>>
    setDisplayModal: Dispatch<SetStateAction<boolean>>
    showSlideControls?: boolean
  }
}

export default GalleryModalButtons
