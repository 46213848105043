/* eslint-disable complexity */
import React from 'react'
import { BookImage } from '@shared/interfaces/BookImage'
import TagRibbon from '@site/components/atoms/TagRibbon'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { ReactComponent as RoundTopHelperSVG } from '@site/assets/helpers/rounded-top.svg'
import { ReactComponent as RoundLeftHelperSVG } from '@site/assets/helpers/rounded-left.svg'
import * as S from './styles'
import { Category } from '@shared/interfaces/Category'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { Link } from 'gatsby'

const StaticBookHeader = ( { bookTitle, bookImage, next, previous, category }: StaticBookHeader.Props ): React.ReactElement => {
  const color = category === 'curadoria' ? '#1B2A68' : '#B74D00'
  const coverImage = getImage( bookImage.cover.localFile )!
  const bg = getImage( bookImage.background.localFile )
  const backgroundImage = convertToBgImage( bg )

  return (
    <>
      <S.HeaderBackgroundImage
        { ...backgroundImage }
        className="mobile-only"
      >
        <RoundTopHelperSVG
          className="book-header__round-top"
          height="14"
          width="100%"
        />
      </S.HeaderBackgroundImage>

      <S.HeaderBackgroundImage
        { ...backgroundImage }
        className="desktop-only"
      >
        <RoundLeftHelperSVG
          className="book-header__round-left"
          height="100%"
          width="14"
        />
      </S.HeaderBackgroundImage>

      <S.DesktopHelper>
        <S.ContentContainer>
          <TagRibbon
            color={ bookImage.flagHexColor }
            height={ 90 }
            width={ 64 }
          />

          <S.ArrowAnchor
            $color={ color }
            $disabled={ !previous }
            className="book-header__left-arrow-anchor"
          >
            <Link to={ previous ? `${process.env[ 'GATSBY_SITE_PATH' ]}${previous}` : '#' }>
              <ArrowLeft color={ color } />
            </Link>
          </S.ArrowAnchor>

          <S.BookCover>
            <GatsbyImage
              image={ coverImage }
              alt={ `Capa do livro ${bookTitle}` }
            />
          </S.BookCover>

          <S.ArrowAnchor
            $color={ color }
            $disabled={ !next }
            className="book-header__right-arrow-anchor"
          >
            <Link to={ next ? `${process.env[ 'GATSBY_SITE_PATH' ]}${next}` : '#' }>
              <ArrowRight color={ color } />
            </Link>
          </S.ArrowAnchor>
        </S.ContentContainer>
      </S.DesktopHelper>
    </>
  )
}

namespace StaticBookHeader {
  export interface Props {
    bookTitle: string
    bookImage: BookImage
    next: string
    previous: string
    category: Category.Type
  }
}

export default StaticBookHeader
