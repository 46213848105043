import React from 'react'
import BaseStructure from '@site/components/organisms/BaseStructure'
import BookGallery from '@site/components/organisms/BookGallery'
import BookButtons from '@site/components/molecules/BookButtons'
import BookMetadata from '@site/components/organisms/BookMetadata'
import BookComplementary from '@site/components/organisms/BookComplementary'
import { Category } from '@shared/interfaces/Category'
import { Book } from '@shared/interfaces/Book'
import BookHeader from '@site/components/organisms/BookHeader'
import * as S from './previousKits-book-styles'
import Button from '@site/components/atoms/Button'
import { previousKitsTexts } from '@shared/interfaces/BookTexts'
import * as PathNavigation from '../components/PathNavigation'
import { ReactComponent as ArrowLeft } from '@site/images/kitsPassados/arrow-left.svg'
import { ReactComponent as ArrowUp } from '@site/images/kitsPassados/arrow-up.svg'
import { colors } from '@shared/interfaces/Colors'
import BookRatings from '@site/components/organisms/BookRatings'
import { motion } from 'framer-motion'
import CommonHead from '@shared/meta/CommonHead'
import { HeadProps } from 'gatsby'
import SubscriptionOffer from '@site/components/organisms/SubscriptionOffer'

function generateMetaTitle( bookTitle: string, bookAuthor: string, category: string ) {
  let title = `${bookTitle}, ${bookAuthor} | Conheça a TAG ${category}`

  if ( title.length > 55 ) {
    title = `${bookTitle}, ${bookAuthor} | TAG ${category}`
  }

  if ( title.length > 55 ) {
    title = `${bookTitle}, ${bookAuthor} | TAG`
  }

  return title
}

export function Head( props: HeadProps<any, any> ) {
  const { 'pageContext': { category, 'node': { 'book': { book, aboutBook }, metadata } } } = props
  const upperCaseCategory = category === 'curadoria' ? 'Curadoria' : 'Inéditos'
  const description = () => {
    if ( metadata?.description ) {
      return metadata.description
    } else if ( aboutBook?.synopsis ) {
      return aboutBook.synopsis.substring( 0, 150 )
    } else {
      return ''
    }
  }

  return (
    <CommonHead
      title={ generateMetaTitle( book.title, book.authorship, upperCaseCategory ) }
      description={ description() }
    />
  )
}

const BookPage = ( data: PreviousKitsBook.Props ) : React.ReactNode => {
  const { 'pageContext': { node, next, previous, category } } = data
  const { book, clubs } = node
  const MotionContent = motion( S.ContainerContent )

  return (
    <BaseStructure clubs={ clubs }>
      <S.Container>
        <BookHeader
          bookImage={ book.bookImage }
          bookTitle={ book.book.title }
          next={ next.slug }
          previous={ previous.slug }
          category={ category }
        />
        <MotionContent
          initial={{ 'opacity': '0' }}
          animate={{ 'opacity': [ '0', '1' ] }}
          transition={{ 'duration': 0.5 }}
          color={ colors[ category ].OUTLINE!.BUTTON_COLOR! }
        >
          <S.ButtonWrapper
            color={ colors[ category ].OUTLINE!.BUTTON_COLOR }
          >
            <Button
              backgroundColor={ colors[ category ].OUTLINE!.BUTTON_BACKGROUND }
              border={ colors[ category ].OUTLINE!.BUTTON_COLOR }
              color={ colors[ category ].OUTLINE!.BUTTON_COLOR }
              hover={ colors[ category ].OUTLINE!.BUTTON_HOVER }
              href={ `${PathNavigation.kitsPassados}` }
            >
              <ArrowLeft className="return-button" />
              { previousKitsTexts!.RETURN }
            </Button>
          </S.ButtonWrapper>
          <BookMetadata
            book={ book }
            category={ category }
          />
          {Boolean( book.bookGallery && book.bookGallery.length ) && <BookGallery galleryPhotos={ book.bookGallery! } />}
          <BookButtons
            category={ category }
            link={ book.book.urlStore }
            page="bookKits"
          />
          {book.aboutBook &&
          <BookComplementary
            category={ category }
            aboutBook={ book.aboutBook }
          />}
          {Boolean( book.comments && book.comments.length ) &&
          <BookRatings
            key={ book.comments![ 0 ]?.id }
            category={ category }
            title={ previousKitsTexts.BOOKRATINGS_TITLE }
            comments={ book.comments! }
          />}
          <Button
            backgroundColor={ colors[ category ].OUTLINE!.BUTTON_BACKGROUND }
            className="previus-kit__button--go-to-top"
            color={ colors[ category ].OUTLINE!.BUTTON_COLOR }
            border={ colors[ category ].OUTLINE!.BUTTON_COLOR }
            hover={ colors[ category ].OUTLINE!.BUTTON_HOVER }
            onClick={ () => {
              window.scrollTo( { 'top': 0, 'behavior': 'smooth' } )
            } }
          >
            <ArrowUp />
            { previousKitsTexts.GO_TO_TOP_TEXT }
          </Button>

        </MotionContent>
      </S.Container>
    </BaseStructure>
  )
}

namespace PreviousKitsBook {
  export interface Props {
    pageContext: {
      category: Category.Type,
      node: {
        id: string,
        book: Book
        clubs: SubscriptionOffer.Props
      },
      previous: {
        title: string,
        slug: string
      },
      next: {
        title: string,
        slug: string
      }
    }
  }
}

export default BookPage
