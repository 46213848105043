import styled from 'styled-components'

const Container = styled.div`
height: auto;
`

const EventWrapper = styled.div`
cursor: pointer;
p {
    text-align: right;
    letter-spacing: 0.025em;
  }  
`
export { Container, EventWrapper }
