import { Category } from '@shared/interfaces/Category'
import { Typography } from '@taglivros/tag-components'
import React from 'react'
import * as S from './styles'
import ReactMarkdown from 'react-showdown'

const TextBlock = ( { category, title, 'children': content } : TextBlock.Props ): React.ReactElement => {
  const titleColor = {
    'curadoria': 'TAG1-600',
    'ineditos': 'TAG2-650-TXT'
  } as const

  return (
    <S.Container className="text-block">
      <Typography
        color={ titleColor[ category ] }
        as="h2"
        fontFamily="notoSans"
        fontWeight="xl"
        fontSizeMobile="m"
        fontSizeDesktop="m"
        variant="customText"
      >
        { title }
      </Typography>
      <Typography
        color="GREY-700-TXT"
        as="p"
        fontFamily="notoSans"
        fontWeight="s"
        fontSizeMobile="xs"
        fontSizeDesktop="xs"
        variant="customText"
      >
        <ReactMarkdown markdown={ content } />
      </Typography>
    </S.Container>
  )
}

namespace TextBlock {
  export interface Props {
    category: Category.Type
    title: string
    children: string
  }
}

export default TextBlock
