import styled from 'styled-components'

const Container = styled.div`
  text-align: center;
`

const Button = styled.button<Button.Props>`
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: ${props => props.theme.cores.WHITE};
  opacity: ${props => props.$disabled ? '0.3' : '1'};
  cursor: ${props => props.$disabled ? 'not-allowed' : 'pointer'};
`

namespace Button {
  export interface Props {
    $disabled?: boolean
  }
}

export { Container, Button }
