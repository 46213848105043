/* eslint-disable no-shadow */

const enum ColorFactor {
  RED = 0.2126,
  GREEN = 0.7152,
  BLUE = 0.0722,
}

function getContrastColor( bgColor: string ): 'WHITE' | 'GREY-900-TXT' {
  const color = ( bgColor.charAt( 0 ) === '#' ) ? bgColor.substring( 1, 7 ) : bgColor
  const red = parseInt( color.substring( 0, 2 ), 16 )
  const green = parseInt( color.substring( 2, 4 ), 16 )
  const blue = parseInt( color.substring( 4, 6 ), 16 )
  const uicolors = [ red / 255, green / 255, blue / 255 ]

  const colors: number[] = uicolors.map( ( color ) => {
    if ( color <= 0.03928 ) {
      return color / 12.92
    }
    return ( ( color + 0.055 ) / 1.055 ) ** 2.4
  } )
  const luminance = ( ColorFactor.RED * colors[ 0 ]! )
    + ( ColorFactor.GREEN * colors[ 1 ]! )
    + ( ColorFactor.BLUE * colors[ 2 ]! )

  return luminance > 0.179 ? 'GREY-900-TXT' : 'WHITE'
}

export { getContrastColor }
