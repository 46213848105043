import styled, { css } from 'styled-components'
import GatsbyBackgroundImage from 'gatsby-background-image'

const HeaderBackground = styled.div`
  position:relative;

  height: 300px;
  margin: 48px 0 34px;

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    position: sticky;
    top: 64px;
    height: calc(100vh - 64px);
    width: calc(50vw - 200px);
    margin: 0;
  }
`

const HeaderBackgroundImage = styled( GatsbyBackgroundImage )`
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Native override */
  position: absolute !important;

  > .book-header__round-top {
    position: absolute;
    bottom: -2px;
    left: 0;

    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      display: none;
    }
  }

  > .book-header__round-left {
    display: none;

    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`

namespace HeaderBackgroundImage {
  export interface Props {
    $backgroundImage: string
  }
}

const DesktopHelper = styled.div`
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    display: flex;
    justify-content: end;
    width: 350px;
    margin-left: calc(50vw - 470px);
  }
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px 0;

  .tag-ribbon {
    display: none;
    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      display: block;
      order: 1;
      align-self: start;
      width: auto;
      margin-bottom: 24px;
      filter: drop-shadow(0px 2px 8px rgba(19, 26, 45, 0.4));
    }
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    justify-content: left;
    flex-wrap: wrap;
    width: min-content;
    max-height: 100%;
    padding: 0 0 24px;
  }
`

const ArrowAnchor = styled.div<ArrowAnchor.Props>`
  > a {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 32px;
    width: 32px;
    margin:8px;

    background-color: #fff;
    border-radius: 50%;
    border: 2px solid ${props => props.$color};

    ${props => props.$disabled && css`
      opacity: 0.3;
      cursor: not-allowed;
    `}
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    > a {
      margin-top: 40px;
    }

    &:not(:last-child) {
      order: 3;
    }

    &:last-child {
      order: 4;

      > a {
        margin-left: 8px;
      }
    }
  }
`

namespace ArrowAnchor {
  export interface Props {
    $disabled: boolean
    $color: string
  }
}

const BookCover = styled.div`
  z-index: 1;
  height: 310px;

  & .gatsby-image-wrapper {
    height: 100%;

    & > div {
      height: 100%;
    }
  }

  & > * {
    filter: drop-shadow(0px 2px 8px rgba(19, 26, 45, 0.2)) !important;
    & img {
      border-radius: 8px;
    }
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    display: flex;
    align-items: end;
    order: 2;
    height: calc(100vh - 362px);
    width: max-content;

    & img {
      height: calc(100vh - 282px);
      max-height: 545px;
    }

    @media(max-height: 747px) {
      height: calc(100vh - 282px);
    }
  }

`

export { HeaderBackground, HeaderBackgroundImage, DesktopHelper, ContentContainer, ArrowAnchor, BookCover }
