import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

const ArrowLink = styled( Link )<ArrowLink.Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;  
  > svg {
    ${props => props.$scrollPosition <= 90 && css`
      margin-top: 8px;
      margin-left: 8px;
  `}  
  }
  ${props => props.$scrollPosition > 90 && css`  
    margin-right: 8px;
  `}  
`

const Container = styled.div<Container.Props>`
  position: fixed;
  top: 47px;
  left: 0;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 100vw;
  height: 40px;

  background-color: transparent;
  transition: all 0.2s;

  > .return-button {
    margin: 8px;
  }

  > .tag-ribbon {
    display: ${props => props.$displayRibbonBeforeScroll ? 'block' : 'none'};
    margin-top: 25px;
  }

  ${props => props.$scrollPosition > 90 && css`
    height: 40px;
    background-color: ${props.$backgroundColor};

    > .return-button {
      margin: 0 8px 0 0;
    }

    > .tag-ribbon {
      display: block;
      margin-top: 25px;
    }
  `}
`

const Title = styled.div<Title.Props>`
  display: none;
  ${props => props.$scrollPosition > 90 && css`
    display: block;
    width: calc(100% - 110px);
    line-height: 20px;
  `}
`

namespace ArrowLink {
  export interface Props {
    $scrollPosition: number
  }
}

namespace Container {
  export interface Props {
    $backgroundColor: string
    $scrollPosition: number
    $displayRibbonBeforeScroll: boolean
  }
}

namespace Title {
  export interface Props {
    $scrollPosition: number
  }
}

export { Container, ArrowLink, Title }
