import React from 'react'
import * as S from './styles'
import starsFillHelpper from './starsFillHelpper'

const Stars = ( rating: Stars.Props ): JSX.Element => {
  return (
    <S.Container
      className="stars"
    >
      {starsFillHelpper( rating )}
    </S.Container>
  )
}

namespace Stars {
  export interface Props {
    rating: number
  }
}

export default Stars
